<template>
  <!-- 机票列表 改签 -->
  <SearchUI :query="query" @updateDate="updateDate" @chooseTicket="chooseTicket" :isNormal="false" :routename="'searchAircraftChange'"/>
</template>

<script>
import { mapGetters } from "vuex";
import SearchUI from "./components/SearchAircraft";
export default {
  components: { SearchUI },
  computed: {
    ...mapGetters(["aircraftOrderChange"]),
    // 丢给子组件的查询参数
    query: function () {
      let obj = {
        depCityCode:this.aircraftOrderChange.order.departCity,
        arrCityCode: this.aircraftOrderChange.order.arriveCity,
        orderSerialNo: this.aircraftOrderChange.order.orderSerialNo,
        depDate: this.aircraftOrderChange.order.departDate,
        corp: this.aircraftOrderChange.order.corp
      }
      return obj
    }
  },
  methods: {
    // 更新aircraftOrderChange的时间，把整个aircraftOrderChange结构都传过去
    updateDate(time) {
      this.aircraftOrderChange.order.departDate =time
      this.$store.dispatch(
        "aircraft/setAircraftOrderChange",
        this.aircraftOrderChange
      )
    },
    // 选票（比价在子组件完成了）
    chooseTicket(tickets, query) {
      // 把比价结果放进vuex
      this.$store.dispatch("aircraft/setAircraftTicket", {
        data: tickets,
        index: 0
      });
      this.$router.push({
        path: "/orderAircraftChange",
        query: query
      });
    }
  }
}
</script>

